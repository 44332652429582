import apiService from '@/utils/api-client'
import config from "@/utils/appConfig";

export default {
	// eslint-disable-next-line no-unused-vars
  getUsersList({ commit }, { count, skip, email }) {
    return apiService.get('admin/users-list', { params: { count, skip, email }, baseURL: config.VUE_APP_API_USERS_URL })
  },
  // eslint-disable-next-line no-unused-vars
  getUsersStatistics({ commit }, { id, startAt, endAt = +new Date(), tags = ["REPORT_GET", "MONITORING_ADD"] }) {
    if (startAt) {
      const startAtDate = new Date(startAt);
      startAtDate.setHours(0, 0, 0, 0);
      startAt = startAtDate.getTime();
    }

    const endAtDate = new Date(endAt);
    endAtDate.setHours(23, 59, 59, 999);
    endAt = endAtDate.getTime();

    return apiService.get(`admin/users/${id}/usage-statistics`, { params: { startAt, endAt: !startAt ? undefined : endAt, tags }, baseURL: config.VUE_APP_API_USERS_URL })
  },
  // eslint-disable-next-line no-unused-vars
  setActiveUser({ commit }, data) {
    return apiService.put('admin/set-active-status', data, { baseURL: config.VUE_APP_API_USERS_URL })
  },
  // eslint-disable-next-line no-unused-vars
  getUser({ commit }, id) {
    return apiService.get(`admin/users/${id}`, { baseURL: config.VUE_APP_API_USERS_URL })
  },
  // eslint-disable-next-line no-unused-vars
  getUserActivityList({ commit }, { id, sortField, sortOrder, count = 10, skip = 0 }) {
    return apiService.get(`admin/users/${id}/activity-logs`, { params: { count, skip, sortOrder, sortField },  baseURL: config.VUE_APP_API_USERS_URL })
  },
  editUser(ctx, { id, body }) {
    return apiService.patch(`admin/users/${id}`, body, { baseURL: config.VUE_APP_API_USERS_URL })
  },
  deleteUser(ctx, id) {
    return apiService.delete(`admin/users/${id}`, { baseURL: config.VUE_APP_API_USERS_URL })
  },
	// eslint-disable-next-line no-unused-vars
	sendInviteUser({ commit }, { email, role, activeTo = null, companyName, requestsTotal = null }) {
    return apiService.post('admin/send-invitation', { email, role, activeTo, companyName, requestsTotal, siteUrl: window.location.origin }, { baseURL: config.VUE_APP_API_USERS_URL })
  },
	// eslint-disable-next-line no-unused-vars
	resetPassword({ commit }, email) {
    return apiService.get(`users/reset-password/${email}`, { params: { siteUrl: window.location.origin }, baseURL: config.VUE_APP_API_USERS_URL })
  },
}
