export const capitalizeFirstLetter = (val) => {
  if(typeof val !== 'string' || !val.trim() || !isNaN(parseInt(val.charAt(0)))) return val
  return val ? val.charAt(0).toUpperCase() + val.slice(1) : '';
}

export const isValidURL = (string) => {
  let res = string.match(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i);
  return (res !== null)
};

export const sendFormatter = (val) => {
  return val.trim();
}

export const getValueByNestedKey = (obj, key) => {
    const keys = key.split('.');
    let value = obj;
    for (let i = 0; i < keys.length; i++) {
      if (!value) {
        return undefined;
      }
      value = value[keys[i]];
    }
    return value;
}

export const trancateString  = (val, count = 4) => {
  if (!val) return ''
  if (val.length <= count * 2) return val

  return`${val.substr(0, count)}...${val.substr(val.length - count)}`;
}

export const formatterRecalcApproveMessage = ({ name, totalTime }) => {
    return name && totalTime ? `Updating ${name} will cause a lot of recalculations. Large number of recalculations will slow down operations. Approximate execution time is ${totalTime}. Do you want to continue?` : null
}

export const spamMessagesCombine = ({ meta, selfFunding }) => {
  if (selfFunding) {
    return 'Due to the filtering of transactions below $1 USD and the presence of only self-transactions, accessing this wallet or generating a report is impossible.'
  }

  if (meta?.spamAmount && meta?.cashbackCount) {
    return 'Due to the filtering of transactions below $1 USD and the presence of only self-transactions, accessing this wallet or generating a report is impossible.'
  }

  if (meta?.cashbackCount) {
    return 'This address only contains self-transactions (transactions sent to itself), accessing this wallet or generating a report is impossible.'
  }

  if (meta?.spamAmount) {
    return 'Due to the filtering of transactions below $1 USD, accessing this wallet or generating a report is impossible.'
  }
}

export const getFirstLetter = (e) => e?.charAt(0).toUpperCase() || '';

