<template>
  <div
    class="side-bar"
    :class="{ 'side-bar__shrunk': isShrunk }"
  >
    <div class="side-bar__header">
      <template>
        <div
          v-show="!isShrunk || isMobile"
          class="logo-wrap"
        >
          <Logo :class="[{'default-logo': appConfig.VUE_APP_THEME && appConfig.VUE_APP_THEME === 'default' }]" />
          <!--          <LogoNY :class="[{'default-logo': appConfig.VUE_APP_THEME && appConfig.VUE_APP_THEME === 'default' }]" />-->
        </div>
      </template>
      <div class="flex align-center">
        <gl-icon
          v-if="isShrunk && isMobile && $route.name === 'analytics'"
          class="mr-3"
          height="24"
          name="search-graph"
          width="24"
          @click="openMobileSearchOnGraphBar"
        />
        <gl-icon
          v-if="isShrunk && isMobile && $route.name === 'analytics'"
          class="mr-3"
          height="18"
          name="equalizer"
          width="18"
          @click="openMobileViewBar"
        />
        <gl-icon
          v-if="isShrunk && isMobile && $route.name === 'analytics'"
          class="mr-3"
          height="24"
          name="cases"
          width="24"
          @click="openMobileCases"
        />
        <gl-icon
          v-if="isShrunk"
          :class="{ 'sidebar-open-icon__shrunk': isShrunk }"
          height="24"
          name="sidebar-open"
          width="24"
          @click="setShrunk(false)"
        />
        <gl-icon
          v-else
          height="12"
          name="sidebar-close"
          width="24"
          @click="setShrunk(true)"
        />
      </div>
    </div>
    <div class="flex column space-between side-bar__main--wrap">
      <div class="side-bar__main">
        <router-link
          v-if="featureAccess('VISION')"
          active-class="side-bar__nav-link--active"
          class="side-bar__nav-link"
          :class="{'side-bar__nav-link--shrunk': isShrunk}"
          to="/analytics"
        >
          <gl-icon
            height="24"
            name="visual-tool"
            width="24"
          />
          <span
            v-show="!isShrunk"
            class="side-bar__nav-link--title"
          >
            GL Vision
          </span>
        </router-link>
        <router-link
          v-if="featureAccess('REPORT')"
          active-class="side-bar__nav-link--active"
          class="side-bar__nav-link"
          :class="{'side-bar__nav-link--shrunk': isShrunk}"
          to="/report"
        >
          <gl-icon
            height="24"
            name="risk-report"
            width="24"
          />
          <span
            v-show="!isShrunk"
            class="side-bar__nav-link--title"
          >
            Risk Report
          </span>
        </router-link>
        <router-link
          v-if="featureAccess('MONITORING')"
          active-class="side-bar__nav-link--active"
          class="side-bar__nav-link"
          :class="{'side-bar__nav-link--shrunk': isShrunk}"
          to="/monitoring"
        >
          <gl-icon
            height="24"
            name="monitoring"
            width="24"
          />
          <span
            v-show="!isShrunk"
            class="side-bar__nav-link--title"
          >
            Monitoring
          </span>
        </router-link>
        <router-link
          v-if="featureAccess('MY_CASES')"
          active-class="side-bar__nav-link--active"
          class="side-bar__nav-link"
          :class="{'side-bar__nav-link--shrunk': isShrunk}"
          to="/cases"
        >
          <gl-icon
            height="24"
            name="cases"
            width="24"
          />
          <span
            v-show="!isShrunk"
            class="side-bar__nav-link--title"
          >
            My Cases
          </span>
        </router-link>
        <router-link
          v-if="featureAccess('SCORE_REQUESTS')"
          active-class="side-bar__nav-link--active"
          class="side-bar__nav-link"
          :class="{'side-bar__nav-link--shrunk': isShrunk}"
          to="/api-requests"
        >
          <gl-icon
            height="24"
            name="api-requests"
            width="24"
          />
          <span
            v-show="!isShrunk"
            class="side-bar__nav-link--title"
          >
            Score Requests
          </span>
        </router-link>
        <router-link
          v-if="featureAccess('SANKEY')"
          active-class="side-bar__nav-link--active"
          class="side-bar__nav-link"
          :class="{'side-bar__nav-link--shrunk': isShrunk}"
          to="/sankey-analytics"
        >
          <gl-icon
            height="24"
            name="sankey-analytics"
            width="24"
          />
          <span
            v-show="!isShrunk"
            class="side-bar__nav-link--title"
          >
            Sankey Analytics
          </span>
        </router-link>
        <router-link
          v-if="featureAccess('COUNTERPARTY')"
          active-class="side-bar__nav-link--active"
          class="side-bar__nav-link"
          :class="{'side-bar__nav-link--shrunk': isShrunk}"
          to="/counterparty"
        >
          <gl-icon
            height="24"
            name="counterparty"
            width="24"
          />
          <span
            v-show="!isShrunk"
            class="side-bar__nav-link--title"
          >
            Counterparty
          </span>
        </router-link>
        <router-link
          v-if="featureAccess('ALERTS')"
          active-class="side-bar__nav-link--active"
          class="side-bar__nav-link"
          :class="{'side-bar__nav-link--shrunk': isShrunk}"
          to="/alerts"
        >
          <div>
            <div
              v-show="isShrunk && unreadNotificationCount > 0"
              class="alert-counter ml-1 mb-1"
            >
              {{ unreadNotificationCount > 1000 ? '1000+' : unreadNotificationCount }}
            </div>
            <gl-icon
              height="24"
              name="alerts"
              width="24"
            />
          </div>
          <span
            v-show="!isShrunk"
            class="side-bar__nav-link--title"
          >
            Alerts
          </span>
          <div
            v-show="!isShrunk && unreadNotificationCount > 0"
            class="alert-counter ml-2"
          >
            {{ unreadNotificationCount > 1000 ? '1000+' : unreadNotificationCount }}
          </div>
        </router-link>
        <router-link
          v-if="featureAccess('ENTITY')"
          active-class="side-bar__nav-link--active"
          class="side-bar__nav-link"
          :class="{'side-bar__nav-link--shrunk': isShrunk}"
          to="/entity-explorer"
        >
          <gl-icon
            height="24"
            name="entity-explorer"
            width="24"
          />
          <span
            v-show="!isShrunk"
            class="side-bar__nav-link--title"
          >
            Entity Explorer
          </span>
        </router-link>
        <div
          class="side-bar__nav-link"
          :class="[{'side-bar__has-open-sub-item': isPreferencesSubMenuOpen}, {'side-bar__nav-link--shrunk': isShrunk}]"
          @click="openSubMenu('Preferences')"
        >
          <div class="relative">
            <gl-icon
              height="24"
              name="preferences"
              width="24"
            />
          </div>
          <div class="fullwidth flex align-center space-between">
            <span
              v-show="!isShrunk"
              class="side-bar__nav-link--title"
            >
              Preferences
            </span>
            <gl-icon
              :class="`gl-user-dropdown__arrow-icon--${isPreferencesSubMenuOpen ? 'opened' : 'closed'}`"
              :height="24"
              name="arrow-down"
              :width="24"
            />
          </div>
        </div>
        <transition name="fade">
          <div
            class="sub-menu__wrap"
            :class="{'sub-menu__open': isPreferencesSubMenuOpen && !isShrunk}"
          >
            <router-link
              v-if="featureAccess('ALERTS')"
              active-class="side-bar__nav-link--active"
              class="side-bar__nav-link side-bar__sub-nav-link"
              :class="{'side-bar__nav-link--shrunk': isShrunk}"
              to="/alerting"
            >
              <gl-icon
                :height="24"
                name="alerting"
                :width="24"
              />
              <span class="side-bar__nav-link--title">
                Alerting
              </span>
            </router-link>
            <router-link
              v-if="featureAccess('SCORING_PROFILES')"
              active-class="side-bar__nav-link--active"
              class="side-bar__nav-link side-bar__sub-nav-link"
              :class="{'side-bar__nav-link--shrunk': isShrunk}"
              to="/scoring-profiles"
            >
              <gl-icon
                height="24"
                name="scoring-profiles"
                width="24"
              />
              <span
                v-show="!isShrunk"
                class="side-bar__nav-link--title"
              >
                Scoring Profiles
              </span>
            </router-link>
            <router-link
              active-class="side-bar__nav-link--active"
              class="side-bar__nav-link side-bar__sub-nav-link"
              :class="{'side-bar__nav-link--shrunk': isShrunk}"
              :to="{ name: 'profile'}"
            >
              <div
                v-if="alarmActiveTo(userData.activeTo)"
                class="alarm-active-date"
              />
              <gl-icon
                :height="24"
                name="my-profile"
                :width="24"
              />
              <span
                v-show="!isShrunk"
                class="side-bar__nav-link--title"
              >
                My Account
              </span>
            </router-link>
          </div>
        </transition>
        <div
          v-if="isAdmin || isSales"
          class="side-bar__nav-link"
          :class="[{'side-bar__has-open-sub-item': isAdminSubMenuOpen}, {'side-bar__nav-link--shrunk': isShrunk}]"
          @click="openSubMenu('Admin')"
        >
          <div class="relative">
            <gl-icon
              height="24"
              name="admin-panel"
              width="24"
            />
            <div
              v-if="(hasUntrackedTags || hasUntrackedType) && isShrunk"
              class="warning-noty mt-1 ml-3"
              style="position: absolute; top: -4px; right: -2px"
            />
          </div>
          <div class="fullwidth flex align-center space-between">
            <div class="flex side-bar__nav-link--wrap-warning">
              <span
                v-show="!isShrunk"
                class="side-bar__nav-link--title"
              >
                Admin Panel
              </span>
              <div
                v-if="isAdmin && (hasUntrackedTags || hasUntrackedType || hasNotify('labeling')) && !isAdminSubMenuOpen && !isShrunk"
                v-popover:tooltip.top="`${untrackedLabelsInfo}`"
                class="warning-noty"
              />
            </div>
            <gl-icon
              :class="`gl-user-dropdown__arrow-icon--${isAdminSubMenuOpen ? 'opened' : 'closed'}`"
              :height="24"
              name="arrow-down"
              :width="24"
            />
          </div>
        </div>
        <transition name="fade">
          <div
            class="sub-menu__wrap"
            :class="{'sub-menu__open': isAdminSubMenuOpen && !isShrunk}"
          >
            <router-link
              v-if="isAdmin || isSales"
              :active-class="$route.name === 'users' ? 'side-bar__nav-link--active' : ''"
              class="side-bar__nav-link side-bar__sub-nav-link"
              :class="{'side-bar__nav-link--shrunk': isShrunk}"
              to="/users"
            >
              <gl-icon
                :height="24"
                name="users-list"
                :width="24"
              />
              <span
                v-show="!isShrunk"
                class="side-bar__nav-link--title"
              >
                Users Manager
              </span>
            </router-link>
            <router-link
              v-if="isAdmin"
              :active-class="$route.name === 'labeling' ? 'side-bar__nav-link--active' : ''"
              class="side-bar__nav-link side-bar__sub-nav-link"
              :class="{'side-bar__nav-link--shrunk': isShrunk}"
              :to="{ name: 'labeling', query: { tab: 'labeling' }}"
            >
              <div class="flex side-bar__nav-link--wrap-warning">
                <gl-icon
                  height="24"
                  name="tagging"
                  width="24"
                />
                <span class="side-bar__nav-link--title">
                  Labeling
                </span>
                <div
                  v-if="hasNotify('labeling') && isAdminSubMenuOpen && !isShrunk"
                  v-popover:tooltip.top="'Unresolved conflicts'"
                  class="warning-noty"
                />
              </div>
            </router-link>
            <router-link
              v-if="isAdmin"
              :active-class="$route.name === 'statistics' ? 'side-bar__nav-link--active' : ''"
              class="side-bar__nav-link side-bar__sub-nav-link"
              :class="{'side-bar__nav-link--shrunk': isShrunk}"
              :to="{ name: 'statistics', query: { tab: 'clusters' }}"
            >
              <gl-icon
                :height="24"
                name="statistic"
                :width="24"
              />
              <span class="side-bar__nav-link--title">
                Statistics
              </span>
            </router-link>
            <router-link
              v-if="isAdmin"
              active-class="side-bar__nav-link--active"
              class="side-bar__nav-link side-bar__sub-nav-link"
              :class="{'side-bar__nav-link--shrunk': isShrunk}"
              :to="{ name: 'scoring'}"
            >
              <div class="flex side-bar__nav-link--wrap-warning">
                <gl-icon
                  :height="24"
                  name="scoring"
                  :width="24"
                />
                <span
                  v-show="!isShrunk"
                  class="side-bar__nav-link--title"
                >
                  Scoring
                </span>
                <div
                  v-if="hasNotify('scoring') && isAdminSubMenuOpen && !isShrunk"
                  v-popover:tooltip.top="`${untrackedLabelsInfo}`"
                  class="warning-noty"
                />
              </div>
            </router-link>
            <router-link
              v-if="isAdmin && featureAccess('ENTITY')"
              active-class="side-bar__nav-link--active"
              class="side-bar__nav-link side-bar__sub-nav-link"
              :class="{'side-bar__nav-link--shrunk': isShrunk}"
              to="/entity-management"
            >
              <gl-icon
                :height="24"
                name="entity"
                :width="24"
              />
              <span class="side-bar__nav-link--title">
                Entity Management
              </span>
            </router-link>
          </div>
        </transition>
      </div>
      <div class="side-bar__footer">
        <div class="flex space-between align-center">
          <div
            class="side-bar__nav-link"
            :class="{'side-bar__nav-link--shrunk': isShrunk}"
            @click="contactSupport"
          >
            <gl-icon
              :height="24"
              name="pods"
              :width="24"
            />
            <span
              v-show="!isShrunk"
              class="side-bar__nav-link--title"
            >
              Contact Support
            </span>
          </div>
          <gl-icon
            class="mr-4 ml-3 pointer"
            :height="24"
            name="logout"
            :width="24"
            @click="logoutUser"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import GlIcon from '@/components/gl-icon'
// Vuex
import {mapActions, mapState, mapMutations, mapGetters} from "vuex";
// Mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'
// Utils
import { alarmActiveTo } from "@/utils/profile";
import {featureAccess} from "@/utils/accesses";
import config from "@/utils/appConfig";
import appConfig from "@/utils/appConfig";
const Logo = () => {
  const prefix = config.VUE_APP_THEME || 'default'
  return import(`@/assets/svg/header/${prefix}-logo.svg?inline`)
};

const LogoNY = () => {
  const prefix = config.VUE_APP_THEME || 'default'
  return import(`@/assets/svg/header/${prefix}-logo-ny.svg?inline`)
};

export default {
  name: 'SidepanelMenu',

  components: {
    GlIcon,
    Logo,
    LogoNY,
  },
  mixins: [deviceWidthMixin],
  data() {
    return {
      alertInterval: null,
      isAdminSubMenuOpen: false,
      isPreferencesSubMenuOpen: false,
      notifyList: {},
      untrackedLabelsInfo: 'Unscored types / tags'
    }
  },
  computed: {
    appConfig() {
      return appConfig
    },
    ...mapGetters('user', ['isSales', 'isAdmin']),
    ...mapState('sidebar', ['isShrunk', 'hasUntrackedTags', 'hasUntrackedType']),
    ...mapState('user', ['userData', 'token']),
    ...mapState('alerts', ['unreadNotificationCount']),
    adminPages() {
      return ['users','labeling', 'scoring', 'statistics', 'entity-management'].includes(this.$route.name)
    },
  },
  watch: {
    $route: {
      async handler(val) {
        if (val && this.isMobile || val && this.isTablet) {
          this.setShrunk(true)
        }
        this.isAdminSubMenuOpen = !!(val && this.adminPages);

        if (this.token) {
          this.getNotificationsCount().then(({ data }) => {
            this.notifyList = data || {}
          })

          if (this.featureAccess('ALERTS')) {
            this.getNotificationList({ $skip: 0, $limit: 1001, read: false, $project: 0, category: 'inbox' }).then((data) => {
              if (data?.count) {
                this.SET_NOTIFICATION_COUNT(data?.count)
              } else {
                this.SET_NOTIFICATION_COUNT(0)
              }
            })
          }

          if (this.userData.id) {
            if (!this.userData.preferredCurrency) {
              await this.setFiat({ preferredCurrency: 'USD' })
            }

            if (!this.userData.scoringFormula) {
              await this.setScoringFormula({ formula: appConfig.VUE_APP_SCORING_FORMULA })
            }
          }
        }
      },
      immediate: true,
    },
    '$store.state.analytics.searchValue': 'setSearchValue',
  },
  mounted() {
    if (this.token && this.featureAccess('ALERTS')) {
      this.getNotificationList({ $skip: 0, $limit: 1001, read: false, $project: 0, category: 'inbox' }).then((data) => {
        if (data?.count) {
          this.SET_NOTIFICATION_COUNT(data?.count)
        } else {
          this.SET_NOTIFICATION_COUNT(0)
        }
      })
      this.alertsGettingInterval()
    }
    if (this.isMobile) {
      this.setShrunk(true)
    }
  },
  methods: {
    featureAccess,
    alarmActiveTo,
    ...mapActions('user', ['logout', 'setFiat', 'setScoringFormula']),
    ...mapActions('sidebar', ['getNotificationsCount']),
    ...mapActions('alerts', ['getNotificationList']),
    ...mapMutations({
      setShrunk: 'sidebar/SET_SHRUNK',
      SET_NOTIFICATION_COUNT: 'alerts/SET_NOTIFICATION_COUNT'
    }),
    alertsGettingInterval(interval = 10000) {
      this.alertInterval = setTimeout(() => {
        this.getNotificationList({ $skip: 0, $limit: 1001, read: false, $project: 0, category: 'inbox' }).then((data) => {
          if (data?.count) {
            this.SET_NOTIFICATION_COUNT(data?.count)
          } else {
            this.SET_NOTIFICATION_COUNT(0)
          }
        }).finally(() => this.alertsGettingInterval())
      }, interval);
    },
    hasNotify(entity) {
      return this.notifyList[entity] > 0
    },
    setSearchValue(val) {
      this.search = val
    },
    openSubMenu(subMenuItemName) {
      if (this.isShrunk) {
        this.setShrunk(false)
        this[`is${subMenuItemName}SubMenuOpen`] = true
        return
      }

      this[`is${subMenuItemName}SubMenuOpen`] = !this[`is${subMenuItemName}SubMenuOpen`]
    },
    contactSupport() {
      window.location.href = 'mailto:support@glprotocol.com'
    },
    logoutUser() {
      localStorage.removeItem('caseId')
      this.logout();
      window.location.assign('/');
    },
    openMobileCases() {
      this.$root.$emit("toggle-toolbar-right-shrunk", false);
      this.$root.$emit('toggle-view-menu', false)
      this.$root.$emit('toggle-cases-menu', true)
      this.$root.$emit('toggle-toolbar-bottom', false)
    },
    openMobileViewBar() {
      this.$root.$emit("toggle-toolbar-right-shrunk", false);
      this.$root.$emit('toggle-cases-menu', false)
      this.$root.$emit('toggle-toolbar-bottom', false)
      this.$root.$emit('toggle-view-menu', true)
    },
    openMobileSearchOnGraphBar() {
      this.$root.$emit("toggle-toolbar-right-shrunk", false);
      this.$root.$emit('toggle-cases-menu', false)
      this.$root.$emit('toggle-view-menu', false)
      this.$root.$emit('toggle-toolbar-bottom', true)
    },
  }
};
</script>

<style scoped>
.alert-counter {
  position: relative;
  height: 20px;
  min-width: 20px;
  background-color: var(--scarlet-red);
  border-radius: 10px;
  color: white;
  font-size: 10px;
  text-align: center;
  line-height: 20px;
  padding: 0 4px;
}

.side-bar {
  width: 200px;
  height: 100svh;
  height: var(--doc-height);
  transition: width 0.2s;
  background: #fff;
  box-shadow: 14px 0 31px -9px rgba(0,0,0,0.46);
  overflow: auto;
}

.side-bar__shrunk {
  width: 50px;
  transition: width 0.2s;
}

.side-bar__header {
  display: flex;
  height: 82px;
  align-items: center;
  justify-content: space-between;
  padding: 4px 16px 0 16px;
  border-bottom: 1px solid #e3e3e3;
}
.side-bar__header svg {
  margin-bottom: 1px;
}

.side-bar__footer {
  border-top: 1px solid #e3e3e3;
}

.side-bar__nav-link {
  align-items: center;
  display: flex;
  padding: 10px 10px 9px 5px;
  color: var(--dark);
  font-weight: 500;
  height: 48px;
  width: 100%;
  line-height: 1.14;
  font-size: 14px;
  position: relative;
  text-decoration: none;
  border-left: 4px solid transparent;
  cursor: pointer;
  transition: all 0.5s ease-out;
}

.side-bar__nav-link:hover {
  background-color: rgba(90, 120, 234, 0.25);
}

.side-bar__nav-link svg {
  margin: -1px 8px 0 7px;
}
.side-bar__nav-link svg.gl-icon--arrow-down {
  margin-right: 0;
}

.side-bar__nav-link--wrap-warning {
  position: relative;
}

.side-bar__nav-link--wrap-warning .warning-noty {
  position: absolute;
  right: -12px;
  top: -4px;
}

.sub-menu__wrap {
  max-height: 0;
  transition: max-height 0.4s;
  overflow: hidden;
}

.sub-menu__open {
  max-height: 500px;
  transition: max-height 0.4s;
  overflow: hidden;
}

.side-bar__nav-link--active {
  font-weight: 600;
  border-left: 4px solid var(--reflex-bluet);
}

.side-bar__sub-nav-link {
  padding-left: 30px;
}

.side-bar__has-open-sub-item {
  /*background: #e6e6e6;*/
}

.alarm-active-date {
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background: red;
  position: absolute;
  top: 10px;
  left: 32px;
}

.side-bar__main--wrap {
  height: calc(100% - 86px);
  /*padding-bottom: 15px;*/
  overflow: hidden;
}

.side-bar__nav-link--shrunk {
  padding-left: 0;
}

.side-bar__nav-link--title {
  margin-top: 3px;
}

.sidebar-open-icon__shrunk {
  margin-left: -3px;
}

.logo-wrap {
  max-width: fit-content;
}

.logo-wrap svg {
  width: 100%;
}

.default-logo {
  width: 110px !important;
}

.side-bar__main {
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 767px) {
  .side-bar {
    position: absolute;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    box-shadow: none;
  }
  .side-bar__shrunk {
    overflow: unset;
    height: 57px;
  }
  .side-bar__header {
    height: 57px;
    border-bottom: none;
  }
}
</style>
