import apiService from '@/utils/api-client'
import config from "@/utils/appConfig";
import { featureAccess } from "@/utils/accesses";

export default {
  async getCountries() {
    try {
      return await apiService.get('https://restcountries.com/v2/all');
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  },
  async getLanguages() {
    try {
      const response = await apiService.get('https://restcountries.com/v3.1/all');

      return response || []
    } catch (error) {
      console.error('Error fetching languages:', error);
    }
  },

  
  // eslint-disable-next-line no-unused-vars
  async getEntityList({ commit }, { searchAutocomplete, $limit, $skip, filterTypeId, filterTagIds, score, name, names }) {
    try {
      if (featureAccess('ENTITY')) {
        const params = { $limit, $skip }

        if (name) {
          params["name.value"] = `${name}`
        }
        if (names) {
          params["name.value"] = names
        }
        if (score) {
          params["score"] = score
        }
        if (filterTagIds) {
          params["data.tags.value._id"] = filterTagIds
        }
        if (filterTypeId) {
          params["data.type.value._id"] = filterTypeId
        }
        if (searchAutocomplete) {
          params["name.value"] = `.*${searchAutocomplete}.*`
        }
        const data = apiService.get(`/entity?&$project[name][value]=1&$project[data][type][value][name]=1&$project[data][type][value][score]=1&$project[data][type][value][_id]=1&$project[id]=1`, { params, baseURL: config.VUE_APP_LABEL_API_URL, })

        return data
      }
      
    } catch (error) {
        console.error(error)
    }
  },
    // eslint-disable-next-line no-unused-vars
  async getEntityById({ commit }, { id, ids, screenshoots }) {
      try {
        const params = {}
        if (ids) {
          params["ids"] = [ids]
          params["$project[data][screenshoots]"] = screenshoots
        }
        if (id) {
          params["id"] = id
        }

        const data = apiService.get('/entity', { params, baseURL: config.VUE_APP_LABEL_API_URL})
        
        return data
      } catch (error) {
          console.error(error)
      }
    },
  // eslint-disable-next-line no-unused-vars
  async createEntity({ commit }, { name, type, data }) {
    try {
      return apiService.post(`/entity`, { name, type, data }, { baseURL: config.VUE_APP_LABEL_API_URL})
    } catch (error) {
      console.error(error)
    }
  },
  // eslint-disable-next-line no-unused-vars
  async editEntity({ commit }, { name, type, data, id }) {
    try {
      return apiService.put(`/entity/${id}`, { name, type, data }, { baseURL: config.VUE_APP_LABEL_API_URL})
      
    } catch (error) {
      console.error(error)
    }
  },
  // eslint-disable-next-line no-unused-vars
  async deleteEntity({ commit }, { id }) {
    try {
      const data = apiService.delete(`/entity/${id}`, { baseURL: config.VUE_APP_LABEL_API_URL})
      return data
    } catch (error) {
      console.error(error)
    }
  },
}
